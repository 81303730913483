import {
  createSlice,
  createEntityAdapter,
  EntityId,
  Update,
  PayloadAction,
  ThunkAction,
  UnknownAction,
  AnyAction,
} from "@reduxjs/toolkit";
import { normalize } from "normalizr";
import { toast } from "react-toastify";
import {
  uniqueId,
  omitBy,
  isUndefined,
  mapValues,
  filter,
  union,
  reverse,
  map as _map,
} from "lodash-es";

import { Schemas } from "../api";
import { actions as LayerActions } from "./layers";
import { RootState } from "../store";
import type { SerializableLayer } from "../querystring";

export type CameraView = {
  position: [number, number, number];
  direction?: [number, number, number];
  up?: [number, number, number];
  heading?: number;
  pitch?: number;
  roll?: number;
  magnitude?: number;
  fov: number;
};
export type InitialView = {
  extent?: [number, number, number, number];
  camera?: CameraView;
  center?: [number, number];
  resolution?: number;
  fov?: number;
  prjExtent?: [number, number, number, number];
};

type AppDefinedMapFields = {
  // App-defined fiels
  initialView: InitialView;
  time: Date;
  startTime: Date;
  stopTime: Date;
};
export type MapEntity = Omit<
  QM.Map,
  "layers" | "projections" | "displayProjection" | "defaultProjection"
> & {
  // Normalized Map Entity Fields
  layers: EntityId[];
  projections: EntityId[];
  displayProjection: EntityId;
  defaultProjection: EntityId;
} & AppDefinedMapFields;
const mapsAdapter = createEntityAdapter<MapEntity, EntityId>({
  selectId: (map) => map.name,
});

function isActionWithMapEntities(action: PayloadAction<{entities: Record<string,any>}>) {
  return action?.payload?.entities?.maps;
}

export const slice = createSlice({
  name: "maps",
  initialState: mapsAdapter.getInitialState(),
  reducers: {
    updateMap: (
      state,
      action: PayloadAction<Update<MapEntity, EntityId> & { entities?: any }>
    ) => {
      const { payload } = action;
      const { id, changes } = payload;
      return mapsAdapter.updateOne(state, { id, changes });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LayerActions.removeLayer, (state, action) => {
        Object.values(state.entities).forEach((m) => {
          if (!m?.layers) return;
          // Use eqeq to compare ids
          // eslint-disable-next-line eqeqeq
          const index = m.layers.findIndex((i) => i == action.payload);
          if (index !== -1) {
            m.layers.splice(index, 1);
          }
        });
      })
      .addMatcher(isActionWithMapEntities, (state, action: PayloadAction<any>) => {
        mapsAdapter.upsertMany(state, action?.payload?.entities?.maps);
      });
  },
});

// Wrapper to take old-style updates to use rtk entityAdapter updateOne
function updateMapEntity(
  id: EntityId,
  changes: Partial<MapEntity>,
  entities = undefined
) {
  return slice.actions.updateMap({
    id,
    changes,
    entities,
  });
}

function addLayerToMap(id: EntityId, layer: Partial<QM.LayerLayer>): ThunkAction<void, RootState, unknown, AnyAction> {
  return (dispatch, getState) => {
    const state = getState();
    const map = state.maps.entities[id];
    if (!layer.id) {
      layer.id = uniqueId();
    }
    if (map.layers.indexOf(layer.id) > -1) {
      toast.warn(`Cannot add duplicate layer to map`);
      return;
    }
    dispatch(
      slice.actions.updateMap({
        id,
        changes: { layers: ([] as any[]).concat(map.layers, [layer.id]) },
        entities: {
          layers: { [layer.id]: layer },
        },
      })
    );
    toast.success(`Layer ${layer.label} added to map`);
  };
}
function setProjection(id: EntityId, val: EntityId, initialView = {}) {
  return updateMapEntity(id, { defaultProjection: val, initialView });
}

function setLayers(id: EntityId, val: EntityId[]) {
  return updateMapEntity(id, { layers: val });
}

const omitUndef = (obj: object) => omitBy(obj, isUndefined);

function layerIsGroupLayer(
  layer: Partial<QM.LayerLayer> | Partial<QM.GroupLayer>
): layer is Partial<QM.GroupLayer> {
  return layer.type === "group";
} 
function layerIsUserAdded(
  layer: Partial<QM.LayerLayer> | Partial<QM.GroupLayer>
): layer is QM.LayerLayer {
  if (layerIsGroupLayer(layer)) return false;
  return (
    layer.type === "bool" || layer.type === 'geotiff' ||
      !!layer.sat ||
      (!!layer.url && layer?.type !== "image")
  );
}
const reverseUnion = (...arrays: any[][]) => reverse(union(..._map(arrays, reverse)));

const updateMapFromQuery =
  (
    id: EntityId,
    map: Partial<Omit<QM.Map, "layers"> & AppDefinedMapFields> & {layers?: SerializableLayer[] | undefined}
  ): ThunkAction<void, RootState, unknown, UnknownAction> =>
  (dispatch, getState) => {
    const entities = {
      layers: {},
    } as {
      layers: Record<EntityId, Partial<QM.LayerLayer | QM.GroupLayer>>;
    };
    const entity = { ...omitUndef(map) };
    const state = getState();
    if (entity.defaultProjection) {
      const projId = +entity.defaultProjection;
      if (!state.projections.entities[projId]) {
        // if projection requested is undefined, unassign (falling back to map default)
        delete entity.defaultProjection;
      }
    }
    if (map.layers) {
      const existingLayers = state.layers.entities;

      const existingMap = state.maps.entities[id];

      const mapLayerIds = existingMap?.layers.flatMap(
        (l) => state.layers.entities[l]?.layers
      );
      // when loading in expression layers we need to assign the server
      const layers = map.layers.map(
        (l: SerializableLayer) => {
          const layer: Partial<QM.LayerLayer | QM.GroupLayer> = { ...l };
          if (!layer.name) {
            layer.name = `${layer.id}`;
          }
          if (layer.type === "bool") {
            layer.source = {
              name: "aleval",
              id: 999999,
              server: existingMap?.booleanTileServer,
            } as QM.SourceSource;
          }
          if (layerIsUserAdded(layer)) {
            if (!layer.options) {
              layer.options = {} as QM.LayerOptions;
            }
            layer.options.canDelete = true;
          }
          return layer;
        }
      );

      // normalize layers
      const normalized = normalize({ id, name: id, layers }, Schemas.MAP);

      // filter out non-existant layers; for example, a layer may be in a permalink that has since been removed from quickmap
      const filteredLayers = omitBy(
        normalized.entities.layers,
        (l) => !layerIsUserAdded(l) && !existingLayers[l.id]
      );

      entities.layers = mapValues(filteredLayers, (layer, key) => {
        //
        if (layer && layer.layers) {
          const existingLayer = existingLayers[key] ;
          const filteredChildLayers = filter(
            layer.layers,
            (l) => layerIsUserAdded(l) || existingLayers[l]
          );
          layer.layers = reverseUnion(
            [...filteredChildLayers],
            [...existingLayer?.layers ?? []]
          );
        }
        return omitUndef(layer);
      });

      mapLayerIds?.forEach((id) => {
        if (entities.layers?.[id]) return;

        // Any map layer that is visible but not in the permalink should be hidden
        if (state.layers?.entities?.[id]?.visible) {
          entities.layers[id] = { id, visible: false };
        }
      });
      const mapLayers = filter(
        map.layers.map((l: SerializableLayer) => l.id),
        (l) =>
          existingLayers[l] || layerIsUserAdded(normalized.entities.layers?.[l])
      );
      // check to keep base layer at bottom
      if (existingMap?.layers[0] === mapLayers[0]) {
        mapLayers.shift();
      }
      entity.layers = reverseUnion([...mapLayers], [...existingMap?.layers ?? []]);
    }
    return dispatch(
      slice.actions.updateMap({
        id,
        changes: entity,
        entities,
      })
    );
  };

type MapsSlice = {
  [slice.name]: ReturnType<typeof slice["reducer"]>;
};
export const {
  selectById: selectMapById,
  selectIds: selectMapIds,
  selectEntities: selectMapEntities,
  selectAll: selectAllMaps,
  selectTotal: selectTotalMaps,
} = mapsAdapter.getSelectors((state: MapsSlice) => state.maps);

export const actions = {
  ...slice.actions,
  updateMapEntity,
  addLayerToMap,
  setProjection,
  updateMapFromQuery,
  setLayers,
};

const reducer = slice.reducer;
export default reducer;
