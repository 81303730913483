import { combineReducers } from "@reduxjs/toolkit";

import user from "../slices/user";
import mapFetch from "../slices/mapFetch";
import mapState from "../slices/mapState";
import maps from "../slices/maps";
import layerLoad from "../slices/layerLoad";
import layers from "../slices/layers";
import projections from "../slices/projections";
import mapSceneImagerySync from "src/slices/mapSceneImagerySync";
import firstPersonView from "src/slices/firstPersonView";

export default combineReducers({
  user,
  mapState,
  mapFetch,
  layerLoad,
  layers,
  maps,
  projections,
  mapSceneImagerySync,
  firstPersonView
});
