import {
  createSlice,
  EntityId,
  PayloadAction,
  removeListener,
  ThunkAction,
  UnknownAction,
} from "@reduxjs/toolkit";
import { activeLayerIds } from "../selectors";
import { CameraView, MapEntity } from "./maps";
import { RootState } from "../store";
import { isEqual } from "lodash-es";
import { isActionWithLayerEntities } from "./layers";
import { isDefined } from "src/lib/utils";

function isChangingProjection(action: PayloadAction<{ entity: MapEntity }>) {
  return !!(
    action.type === "UPDATE_MAP" && action?.payload?.entity?.defaultProjection
  );
}

export type DrawModes = "Point" | "LineString" | "Polygon" | "Arc" | "Geodesic";
type QueryOpts = {
  tab?: string;
  productId?: string | number;
  charts?: string[][];
};

type EntityDatasourceKey = [string | undefined, string];
export type MapState = {
  id?: string;
  tracking?: string[];
  trackingGround?: boolean;
  orbitPoint?: GeoJSON.Position;
  viewOpts?: CameraView;
  showGraticule?: boolean;
  drawMode?: DrawModes;
  layerListFilter?: string;
  showTerrain?: boolean;
  loadingError?: boolean;
  layerStack?: EntityId[];
  selectedFeature?: any[];
  groundTrackFeature?: [string, string];
  queryFeature?: number | string;
  queryOpts: QueryOpts;
  freeCameraZ?: boolean;
  // Feature flags
  isQTSTerrainEnabled?: boolean;
  isWebGLDynamicLayersEnabled?: boolean;
  isCesiumGeoTIFFEnabled?: boolean;
  isCesiumDynamicEnabled?: boolean;
  isCesiumEntityDetailsEnabled?: boolean;
  showContours?: boolean;
  contourInterval?: number;
  showNadirViewer?: boolean;
  showBodiesViewer?: boolean;
  trackingViewers: EntityDatasourceKey[];
  showCompass?: boolean;
  trailType?: number;
  wideTrail?: boolean;
  __debugFakePolarShifted?: boolean;
  showShadows: boolean;
  skipCache: boolean;
};
export const initialState: MapState = {
  id: undefined,
  tracking: undefined,
  trackingGround: undefined,
  orbitPoint: undefined,
  viewOpts: undefined,
  showGraticule: undefined,
  drawMode: undefined,
  layerListFilter: undefined,
  showTerrain: true,
  loadingError: false,
  layerStack: undefined,
  selectedFeature: undefined,
  groundTrackFeature: undefined,
  queryFeature: undefined,
  freeCameraZ: false,
  queryOpts: {},
  isQTSTerrainEnabled: true, // default to using qts in (dev, staging and local)
  isWebGLDynamicLayersEnabled: true,
  isCesiumGeoTIFFEnabled: false,
  isCesiumDynamicEnabled: false,
  isCesiumEntityDetailsEnabled: true,
  showContours: false,
  showCompass: true,
  contourInterval: 100,
  showBodiesViewer: false,
  showNadirViewer: false,
  trailType: 1,
  wideTrail: true,
  trackingViewers: [],
  showShadows: false,
  skipCache: false,
};

const mapState = createSlice({
  name: "mapState",
  initialState,
  reducers: {
    selectMap(state, action) {
      state.id = action.payload;
      if (action.payload) {
        state.loadingError = false;
      }
    },
    cancelTracking(state) {
      state.orbitPoint = undefined;
      state.tracking = undefined;
      state.trackingGround = undefined;
    },
    setOrbitPoint(state, action) {
      state.orbitPoint = action.payload;
      state.viewOpts = undefined;
    },
    setShowGraticule(state, action) {
      state.showGraticule = action.payload;
    },
    setDrawMode(state, action) {
      state.drawMode = action.payload;
      if (action.payload) {
        state.orbitPoint = undefined;
        state.tracking = undefined;
        state.trackingGround = undefined;
      }
    },
    setLayerListFilter(state, action) {
      state.layerListFilter = action.payload;
    },
    setTracking(state, action) {
      state.viewOpts = undefined;
      if (action.payload) {
        state.orbitPoint = undefined;
      }
      state.tracking =
        action.payload === state.tracking ? false : action.payload;
    },
    setTrackingOptions(state, action) {
      state.trackingGround = action.payload;
    },
    toggleTracking(state, action) {
      state.orbitPoint = undefined;
      state.viewOpts = undefined;
      if (action.payload) {
        state.tracking =
          action.payload === state.tracking ? false : action.payload;
        state.trackingGround = undefined;
      } else {
        if (state.tracking) {
          state.tracking = undefined;
        }
        state.trackingGround = undefined;
      }
    },
    selectQueryFeature(state, action) {
      state.queryFeature = action.payload;
      if (!state?.queryOpts?.tab) {
        state.queryOpts.tab = "data";
      }
      state.selectedFeature = undefined;
    },
    setQueryOpts(state, action: PayloadAction<{ key: string; value: any }>) {
      state.queryOpts[action.payload.key as keyof QueryOpts] =
        action.payload.value;
    },
    selectFeature(state, action) {
      if (!state.selectedFeature) {
        state.queryOpts.tab = "";
      }
      state.selectedFeature = action.payload;
      state.queryFeature = undefined;
    },
    clearLayerFilters(state, action) {
      state.layerListFilter = undefined;
    },
    setShowTerrain(state, action) {
      state.showTerrain = action.payload;
    },
    setGroundTrackFeature(state, action) {
      console.log("trackity track", action.payload);
      state.groundTrackFeature = action.payload;
    },
    loadFromQueryState(state, action) {
      const query = action.payload;
      state.tracking = query.tracking || query.followSat;
      state.trackingGround =
        query.trackingOpts ?? query.trackingOptions ?? query.trackingGround;
      state.orbitPoint = query.orbitPoint;
      state.showGraticule = query?.showGraticule ?? state.showGraticule;
      state.layerListFilter = query.layerListFilter;
      state.layerStack = query.layerStack;
      state.selectedFeature = query.selectedFeature;
      state.viewOpts =
        state.tracking || state.orbitPoint ? query?.camera ?? {} : undefined;
      state.queryFeature = query.queryFeature;
      state.queryOpts = { ...query?.queryOpts };
      if (query.contourInterval) {
        state.contourInterval = +query.contourInterval;
        state.showContours = true;
      }
      state.isWebGLDynamicLayersEnabled =
        query.isWebGLDynamicLayersEnabled ?? state.isWebGLDynamicLayersEnabled;
      state.isQTSTerrainEnabled =
        query.isQTSTerrainEnabled ?? state.isQTSTerrainEnabled;
      state.trailType = query.trailType ?? state.trailType;
      state.wideTrail = query.wideTrail ?? state.wideTrail;
      state.showShadows =
        query.showShadows === "true" ||
        query.showShadows === 1 ||
        query.showShadows === true;
      state.skipCache =
        query.skipCache === "true" ||
        query.skipCache === 1 ||
        query.skipCache === true;
    },
    toggleFreeCameraZ(state, action) {
      state.freeCameraZ = action.payload ?? !state.freeCameraZ;
    },
    setLoadingError(state, action) {
      state.loadingError = action.payload;
    },
    setLayerStack(state, action) {
      state.layerStack = action.payload;
    },
    setQTSTerrainEnabled(state, action) {
      state.isQTSTerrainEnabled = action.payload;
    },
    setWebGLDynamicLayersEnabled(state, action) {
      state.isWebGLDynamicLayersEnabled = action.payload;
    },
    setCesiumGeoTIFFEnabled(state, action) {
      state.isCesiumGeoTIFFEnabled = action.payload;
    },
    setCesiumDynamicEnabled(state, action) {
      state.isCesiumDynamicEnabled = action.payload;
    },
    setCesiumEntityDetailsEnabled(state, action) {
      state.isCesiumEntityDetailsEnabled = action.payload;
    },
    setShowContours(state, action) {
      state.showContours = action.payload;
    },
    setContourInterval(state, action) {
      state.contourInterval = action.payload;
    },
    setShowBodiesViewer(state, action) {
      state.showBodiesViewer = action.payload;
    },
    setShowNadirViewer(state, action) {
      state.showNadirViewer = action.payload;
    },
    setTrailType(state, action) {
      state.trailType = action.payload;
    },
    setWideTrail(state, action) {
      state.wideTrail = action.payload;
    },
    setDebugFakePolarShifted(state, action) {
      state.__debugFakePolarShifted = action.payload;
    },
    setShowCompass(state, action) {
      state.showCompass = action.payload;
    },
    addTrackingViewer(state, action) {
      if (!state.trackingViewers.some((val) => isEqual(val, action.payload))) {
        state.trackingViewers = [...state.trackingViewers, action.payload];
      }
    },
    removeTrackingViewer(state, action) {
      state.trackingViewers = state.trackingViewers.filter(
        (val) => !isEqual(val, action.payload)
      );
    },
    toggleTrackingViewer(state, action) {
      if (!state.trackingViewers.some((val) => isEqual(val, action.payload))) {
        state.trackingViewers = [...state.trackingViewers, action.payload];
      } else {
        state.trackingViewers = state.trackingViewers.filter(
          (val) => !isEqual(val, action.payload)
        );
      }
    },
    setShowShadows(state, action) {
      state.showShadows = action.payload;
    },
    toggleSkipCache(state) {
      state.skipCache = !state.skipCache;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(isChangingProjection, (state, action) => {
      state.orbitPoint = undefined;
    });

    builder.addMatcher(
      isActionWithLayerEntities,
      (state, action: PayloadAction<any>) => {
        const layers = action?.payload?.entities?.layers;
        if (isDefined(layers) && isDefined(state.layerStack)) {
          const visibleLayers = Object.entries(layers)?.filter(([key, l]: [string, QM.LayerLayer]) => l.visible && !state.layerStack?.includes(l.id))?.map(([key, l]: [string, QM.LayerLayer]) => l.id);
          state.layerStack = [...state.layerStack, ...visibleLayers];
        }
      }
    );
  },
});

export function loadFromQuery(
  query: any
): ThunkAction<void, RootState, unknown, UnknownAction> {
  return (dispatch) => {
    // support for legacy layer stack
    if (query.showOnlyVisible) {
      if (query.layers) {
        query.layerStack = query.layers.flatMap((l: any) => {
          if (l.visible) return l.id;
          return l.layers.map((l: any) => (l.visible ? l.id : null));
        });
      }
    }
    dispatch(actions.loadFromQueryState(query));
  };
}
export function toggleLayerStack(): ThunkAction<
  void,
  RootState,
  unknown,
  UnknownAction
> {
  return (dispatch, getState) => {
    const state = getState();

    if (state.mapState.layerStack) {
      dispatch(actions.setLayerStack(null));
    } else {
      dispatch(actions.setLayerStack(activeLayerIds(state)));
    }
  };
}
export const { actions } = mapState;
export const {
  setLayerListFilter,
  setShowGraticule,
  setTrackingOptions,
  toggleTracking,
  setDrawMode,
  setShowTerrain,
  clearLayerFilters,
  selectFeature,
  loadFromQueryState,
  toggleFreeCameraZ,
  selectQueryFeature,
  setGroundTrackFeature,
  setQTSTerrainEnabled,
  setWebGLDynamicLayersEnabled,
  setShowContours,
  setContourInterval,
  setCesiumGeoTIFFEnabled,
  setCesiumDynamicEnabled,
  setCesiumEntityDetailsEnabled,
  setDebugFakePolarShifted,
  setShowCompass,
  toggleTrackingViewer,
  toggleSkipCache,
} = mapState.actions;
export default mapState.reducer;
