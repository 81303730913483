import React, { ReactNode, useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useDropzone } from "react-dropzone";

import { map, isEmpty } from "lodash-es";
import { coordEach } from "@turf/meta";

import * as GeoJSON from "../lib/geo/formats/geojson";
import SidePanel from "./base/SidePanel";
import { List, ListItem } from "./base/List";
import { ToggleButton, Button } from "./base/Button";
import { FeatureList } from "./FeatureInfo/FeatureList";
import { FeatureInfo } from "./FeatureInfo";

import { readFileAsText } from "./CreateLayer/GeoJSONBuilder";
import { saveStringAs } from "../lib/download";
import { DrawModes, setDrawMode, selectFeature } from "../slices/mapState";

import { useParams, useNavigate } from "react-router-dom";
import { useLayerGeoJSONByID } from "./fetchGeoJSON";
import { selectLayerById } from "../slices/layers";
import Tooltip from "./base/Tooltip";
import { DownloadIcon, UploadIcon } from "lucide-react";
import {
  PiDotOutline,
  PiLineSegmentsLight,
  PiPolygonLight,
  PiCircleLight,
} from "react-icons/pi";
import { IconType } from "react-icons";
import { actions as LayerActions } from "../slices/layers";
import { addFeatureToGeoJSON, useGeoJSON } from "./hooks/useGeoJSON";

type DrawTypes = {
  [key: string]: {
    type: DrawModes;
    icon: IconType;
  };
};
const DRAW_TYPES: DrawTypes = {
  point: {
    type: "Point",
    icon: PiDotOutline,
  },
  arc: {
    type: "Arc",
    icon: PiLineSegmentsLight,
  },
  polygon: {
    type: "Polygon",
    icon: PiPolygonLight,
  },
  circle: {
    type: "Geodesic",
    icon: PiCircleLight,
  },
};

export function LayerFeatureInfoWrapper() {
  const { layerId, featureId } = useParams();
  const navigate = useNavigate();
  const { geojson, isLoading, info } = useLayerGeoJSONByID(layerId);
  const layer = useAppSelector((state) => selectLayerById(state, layerId!));
  if (!layer || layer.type === "group") return;
  const selectedFeature = geojson?.features?.find(
    (f) => `${f.id}` === featureId
  );
  if (isLoading) return null;
  if (!selectedFeature?.geometry) {
    navigate("../");
    return null;
  }
  const labelProperty = info?.labelProperty
  return (
    <FeatureInfo
      feature={selectedFeature}
      title={layer?.label}
      layerId={layer?.id}
      labelProperty={labelProperty}
    />
  );
}

export function DrawPanel() {
  const { data, actions } = useGeoJSON("user-defined");
  const features = data?.features ?? [];
  const drawMode = useAppSelector((state) => state.mapState.drawMode);
  const selectedFeature = useAppSelector(
    (state) => state.mapState.selectedFeature
  );
  const dispatch = useAppDispatch();
  const createDrawHandler = useCallback(
    (type?: DrawModes) => () => {
      if (drawMode === type) {
        return dispatch(setDrawMode(null));
      }
      dispatch(setDrawMode(type));
    },
    [dispatch, drawMode]
  );
  const onDownload = useCallback(() => {
    saveStringAs(
      JSON.stringify({ type: "FeatureCollection", features }),
      "features.geojson"
    );
  }, [features]);
  const onUpload = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach(async (file) => {
      const data = await readFileAsText(file);
      const geojson = GeoJSON.normalize(JSON.parse(data));

      // remove elevation coord, if present
      coordEach(geojson, function (coords) {
        if (coords.length > 2) coords.splice(2, coords.length);
      });

      addFeatureToGeoJSON("user-defined", geojson.features);
    });
  }, []);
  // TODO: combine vector loading logic with GeoJSONBuilder
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onUpload,
    accept: {
      "application/json": [".json", ".geojson"],
      "text/plain": [".json", ".geojson"],
    },
    multiple: false,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    useFsAccessApi: false,
  });
  return (
    <SidePanel title={<h1>Draw &amp; Query</h1>} {...getRootProps()}>
      <List className="flex flex-row items-center overflow-y-visible border-b border-gray">
        <li className="flex-1 label text-xs mx-1 mt-0.5">Select Tool</li>
        {map(DRAW_TYPES, ({ type, icon: Icon }) => (
          <ListItem key={type} className="!bg-transparent">
            <Tooltip content={type} side="top">
              <ToggleButton
                size="sq"
                isSelected={drawMode === type}
                onChange={createDrawHandler(type)}
              >
                <Icon className="w-5 h-5 m-1" />
              </ToggleButton>
            </Tooltip>
          </ListItem>
        ))}
      </List>
      <FeatureList
        layerId={"user-defined"}
        features={features}
        onFeatureSelect={(feature) =>
          dispatch(selectFeature(["user-defined", feature.id]))
        }
        selectedFeatureId={selectedFeature?.[1]}
        actions={actions}
      />{" "}
      <div className="flex flex-row items-center justify-center border-t border-gray">
        <input {...getInputProps()} />
        <Tooltip content="Export Query Features" side="top">
          <Button
            variant="secondary"
            isQuiet
            isDisabled={isEmpty(features)}
            onPress={onDownload}
          >
            <DownloadIcon size={16} />
          </Button>
        </Tooltip>
        <Tooltip content="Import Query Features" side="top">
          <Button variant="secondary" isQuiet onPress={open}>
            <UploadIcon size={16} />
          </Button>
        </Tooltip>
      </div>
    </SidePanel>
  );
}

export function FeaturesListPanel() {
  const { layerId } = useParams();
  const { geojson, isLoading, filteredGeoJSON, layerProps, actions } =
    useLayerGeoJSONByID(layerId);
  const layer = useAppSelector((state) => selectLayerById(state, layerId!));

  const drawMode = useAppSelector((state) => state.mapState.drawMode);
  const dispatch = useAppDispatch();
  const createDrawHandler = useCallback(
    (type?: DrawModes) => () => {
      if (drawMode === type) {
        return dispatch(setDrawMode(null));
      }
      dispatch(setDrawMode(type));
    },
    [dispatch, drawMode]
  );
  const onDownload = useCallback(() => {
    saveStringAs(JSON.stringify(geojson), "features.geojson");
  }, [geojson]);
  const onUpload = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach(async (file) => {
        const data = await readFileAsText(file);
        const geojson = GeoJSON.normalize(JSON.parse(data));

        // remove elevation coord, if present
        coordEach(geojson, function (coords) {
          if (coords.length > 2) coords.splice(2, coords.length);
        });
        addFeatureToGeoJSON("user-defined", geojson.features);
      });
    },
    [dispatch]
  );
  // TODO: combine vector loading logic with GeoJSONBuilder
  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: onUpload,
    accept: {
      "application/json": [".json", ".geojson"],
      "text/plain": [".json", ".geojson"],
    },
    multiple: false,
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    useFsAccessApi: false,
  });

  if (!layerId || !layer || layer.type === "group") return;
  const labelProperty =
    layer?.style?.labelProperty ??
    layer?.options?.searchFields?.[0] ??
    Object.keys(geojson?.features?.[0]?.properties ?? []).find((p) =>
      ["name", "title", "label", "id"].includes(p.toLowerCase())
    );

  const { searchFields } = layerProps?.options ?? {};
  const { searchFilters } = layer;
  const hasSearchProperty = geojson?.features?.some(
    //@ts-ignore
    (x) => x?.properties?.[searchFields?.[0]]
  );

  if (isLoading) return null;
  const features = filteredGeoJSON?.features ?? geojson?.features;

  return (
    <SidePanel
      title={
        <div>
          <h1>Layer Features</h1>
          <h3>{layer.label}</h3>
        </div>
      }
      {...getRootProps()}
    >
      {false && (
        <List className="flex flex-row items-center overflow-y-visible border-b border-gray">
          <li className="flex-1 label text-xs mx-1 mt-0.5">Select Tool</li>
          {map(DRAW_TYPES, ({ type, icon }) => (
            <ListItem key={type} className="!bg-transparent">
              <Tooltip content={type} side="top">
                <ToggleButton
                  size="sq-md"
                  isSelected={drawMode === type}
                  onChange={createDrawHandler(type)}
                >
                  <span className={`glyphicons ${icon}`} />
                </ToggleButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
      )}
      {hasSearchProperty && (
        <form
          onSubmit={(e) => e.preventDefault()}
          className="my-3 flex flex-row items-center space-x-1"
        >
          <input
            type="text"
            placeholder="Filter by name"
            value={searchFilters ?? ""}
            onChange={(e) =>
              dispatch(
                LayerActions.setLayerSearchFilter(layerId, e.target.value)
              )
            }
            className="w-full"
          />
          {searchFilters?.[0] && filteredGeoJSON?.features && (
            <Tooltip content="Clear search">
              <Button
                size="sq"
                variant="secondary"
                isQuiet
                onPress={() =>
                  dispatch(
                    LayerActions.setLayerSearchFilter(layerId, undefined)
                  )
                }
              >
                <i className="fa fa-close" />
              </Button>
            </Tooltip>
          )}
        </form>
      )}
      <div className="pb-2 font-mono text-xs">
        Showing {features?.length ?? 0} of {geojson?.features?.length ?? 0}{" "}
        features
      </div>
      <FeatureList
        layerId={layerId}
        features={features}
        labelProperty={labelProperty}
        onFeatureSelect={(feature, id) =>
          dispatch(selectFeature(["user-defined", id]))
        }
        actions={actions}
      />
      <div className="flex flex-row items-center justify-center border-t border-gray">
        <input {...getInputProps()} />
        <Tooltip content="Export Query Features" side="top">
          <Button
            variant="secondary"
            isQuiet
            isDisabled={isEmpty(geojson?.features)}
            onPress={onDownload}
          >
            <i className="fa fa-fw fa-download" />
          </Button>
        </Tooltip>
        {/*<Tooltip content="Import Query Features" side="top">
        <Button
          variant="secondary"
          isQuiet
          onPress={open}
        >
          <UploadIcon size={16} />
        </Button>
        </Tooltip>*/}
      </div>
    </SidePanel>
  );
}
