import { PlusIcon, TrashIcon } from "@radix-ui/react-icons";
import { GeoJsonProperties } from "geojson";
import { useState, useRef, useEffect } from "react";
import { cn } from "src/lib/utils";
import React, { useMemo } from "react";
const useAutosizeTextArea = (
  textAreaRef: HTMLTextAreaElement | null,
  value: string,
  maxHeight = 300
) => {
  useEffect(() => {
    if (textAreaRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      textAreaRef.style.height = "0px";
      const scrollHeight = textAreaRef.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      textAreaRef.style.height = Math.min(scrollHeight, maxHeight) + "px";
    }
  }, [textAreaRef, value]);
};
const headerStyles =
  "sticky text-xs top-0 font-light px-1 border-b border-b-qmdark-200 bg-qmdark-900";
const tableInputStyles =
  "pl-2 bg-transparent block tabular-nums text-xs border-none pr-1 py-2 w-full focus-visible:ring-1 focus-visible:bg-qmprimarylight/10 focus-visible:ring-qmprimarylight";

type AddFeaturePropertyFormProps = {
  initKey?: string;
  initValue?: string;
  isSubmitForm?: boolean;
  onSubmit: (key: string, value: string, newKey?: string) => void;
  onDelete?: (key: string) => void;
  className?: string;
};

function AddFeaturePropertyForm({
  initKey,
  initValue,
  isSubmitForm = false,
  onSubmit,
  onDelete,
  className,
}: AddFeaturePropertyFormProps) {
  const [key, setKey] = useState<string | undefined>(initKey);
  const [value, setValue] = useState<string | undefined>(initValue);

  // if initKey changes update key
  useEffect(() => {
    setKey(initKey);
  }, [initKey]);

  // if initValue changes update value
  useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  const [isHovered, setIsHovered] = useState(false);
  const keyInputRef = useRef<HTMLInputElement>(null);
  const valueInputRef = useRef<HTMLTextAreaElement>(null);
  useAutosizeTextArea(valueInputRef.current, value ?? "");
  const handleSubmit = () => {
    if (key && value) {
      if (isSubmitForm) {
        onSubmit(key, value);
        setKey("");
        setValue("");
        keyInputRef.current?.focus();
      } else if (initKey) {
        // edit current values
        onSubmit(initKey, value, key);
      }
    }
  };
  const handleKeyDown = (
    evt: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    inputRef?: React.RefObject<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (evt.key === "Enter") {
      if (inputRef === keyInputRef && !value) {
        valueInputRef.current?.focus();
      } else {
        handleSubmit();
      }
    }
  };
  return (
    <tr
      className={cn("h-8", className)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <td className="py-2" onClick={() => keyInputRef.current?.focus()}>
        <input
          value={key}
          onChange={(e) => setKey(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e, keyInputRef)}
          onBlur={handleSubmit}
          className={tableInputStyles}
          type="text"
          placeholder="New Key"
          ref={keyInputRef}
        />
      </td>
      <td className="relative">
        <textarea
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={handleKeyDown}
          onBlur={handleSubmit}
          className={tableInputStyles + " resize-none"}
          placeholder="New Value"
          ref={valueInputRef}
        />

        {isSubmitForm ? (
          <div className="flex items-center absolute top-1 bottom-1 right-1">
            <button title="Edit Value" onClick={handleSubmit}>
              <PlusIcon className="w-4 h-4 hover:text-qmprimarylight" />
            </button>
          </div>
        ) : (
          isHovered && (
            <div className="flex items-center absolute top-1 bottom-1 right-1">
              <button
                title="Delete Value"
                onClick={() => key && onDelete?.(key)}
              >
                <TrashIcon className="w-4 h-4 text-infrared" />
              </button>
            </div>
          )
        )}
      </td>
    </tr>
  );
}
const nullFn: () => void = () => null;
const FeatureProperties = ({
  properties = {},
  availableProperties = {},
  onChange = nullFn,
}: {
  properties: { [key: string]: any };
  availableProperties: Record<string, any>;
  onChange: (properties: GeoJsonProperties) => void;
}) => {
  const missingProperties = useMemo(() => {
    if (!availableProperties) return [];
    const featureProperties = properties ?? {};
    return Object.keys(availableProperties).filter(
      (key) => !(key in featureProperties)
    );
  }, [availableProperties, properties]);
  const addProperties = (key: string, value: string) => {
    onChange({ ...properties, [key]: value });
  };
  const deleteProperty = (key: string) => {
    const newProperties = { ...properties };
    delete newProperties[key];
    onChange(newProperties);
  };
  const changePropertyKey = (oldKey: string, newKey: string) => {
    // preserve current key order
    const propertiesKeys = Object.keys(properties);
    // create new properties object
    const newProperties = propertiesKeys.reduce((acc, key) => {
      if (key === oldKey) {
        // change property key
        acc[newKey] = properties[oldKey];
      } else if (key !== newKey) {
        // skip if there is a key === newKey it will be overwritten by the newKey
        acc[key] = properties[key];
      }
      return acc;
    }, {} as Record<string, string>);
    onChange(newProperties);
  };
  const changePropertyValue = (key: string, newValue: string) => {
    onChange({ ...properties, [key]: newValue });
  };
  const changeProperty = (key: string, value: string, newKey: string) => {
    if (key !== newKey) {
      changePropertyKey(key, newKey);
    } else {
      changePropertyValue(key, value);
    }
  };

  // TODO: how to handle multiline editing/viewing
  // could use the popup approch for editing similar to placemark
  // but what about viewing? annoying to click popup to see it.

  // TODO (for later): Handle different JSON types: boolean, number, string, array, object -- this may be a larger
  // task to put off for later

  return (
    <div>
      <table className="table text-sm max-h-[400px] border-collapse">
        <thead className="text-left">
          <tr className="h-8 font-light uppercase">
            <th className={headerStyles}>Name</th>
            <th className={headerStyles}>Value</th>
          </tr>
        </thead>
        <tbody className="overflow-y-auto">
          {Object.entries(properties ?? {}).map(([key, value]) => (
            <AddFeaturePropertyForm
              key={key}
              initKey={key}
              initValue={value}
              onSubmit={(key: string, value: string, newKey: string) =>
                changeProperty(key, value, newKey)
              }
              onDelete={() => deleteProperty(key)}
            />
          ))}
          {missingProperties?.map((key: string) => (
            <AddFeaturePropertyForm
              key={key}
              initKey={key}
              initValue=""
              className="opacity-70"
              onSubmit={(key: string, value: string, newKey: string) =>
                changeProperty(key, value, newKey)
              }
              onDelete={() => deleteProperty(key)}
            />
          ))}
          <AddFeaturePropertyForm
            key={-1}
            isSubmitForm={true}
            className="opacity-70"
            onSubmit={(key: string, value: string) => addProperties(key, value)}
          />
        </tbody>
      </table>
    </div>
  );
};

export default FeatureProperties;
