import React from "react";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import { Popover, PopoverContent, PopoverTrigger } from "./base/Popover";
import { HexColorPicker, HexColorInput } from "react-colorful";
import { cn } from "src/lib/utils";
import { XIcon } from "lucide-react";
import { isString } from "lodash-es";
import { Color } from "@cesium/engine";

function ColorSwatch({ color, className }: { color: string, className?: string }) {
  return (
    <div
      className={cn("w-5 h-5 border border-white", className)}
      style={{ backgroundColor: color }}
    ></div>
  );
}


function useColor(color: string | number[]) {

  return React.useMemo(() => {
    if (isString(color)) {
      return color;
    }
    if (Array.isArray(color)) {
      return new Color(color[0] / 255, color[1] / 255, color[2] / 255, color[3]).toCssHexString();
    }
  }, [color])

}
type ColorPickerProps = {
  color: string;
  colors?: string[];
  onChange?: (color: string) => void;
};
export default function ColorPicker({
  color,
  colors = [
    "#c56cf0",
    "#ffb8b8",
    "#ff3838",
    "#ff9f1a",
    "#fff200",
    "#32ff7e",
    "#7efff5",
    "#18dcff",
  ],
  onChange = () => null,
}: ColorPickerProps) {
  const cssColor = useColor(color);
  return (
    <div onClick={(e) => e.stopPropagation()} className="inline-flex items-center">
      <Popover>
        <PopoverTrigger>
          <ColorSwatch color={cssColor ?? 'transparent'} className="w-4 h-4 mx-1 rounded-full"></ColorSwatch>
        </PopoverTrigger>
        <PopoverContent>
          <div className="space-y-2">
            <HexColorPicker
              style={{
                width: "100%",
              }}
              color={cssColor}
              onChange={(newValue) => onChange(newValue)}
            />
            <div>
              <div className="label">Presets: </div>
              <ToggleGroup.Root
                type="single"
                value={cssColor}
                onValueChange={(color) => color && onChange(color)}
                aria-label="Color"
                className="flex items-center jusity-center space-x-1"
              > 
                <ToggleGroup.Item value={'#ffffff00'} aria-label={color}>
                  <div className="relative h-6 w-6 border-0 bg-white text-red-500 flex items-center justify-center overflow-hidden"><XIcon size={30} /></div>
                </ToggleGroup.Item>
                {colors.map((color) => (
                  <ToggleGroup.Item key={color} value={color} aria-label={color}>
                    <ColorSwatch color={color} className="h-6 w-6 border-0" />
                  </ToggleGroup.Item>
                ))}
              </ToggleGroup.Root>
            </div>
            <HexColorInput
              className="input"
              prefixed
              color={cssColor}
              onChange={(newValue) => onChange(newValue)}
            />
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}

export function ColorInput({
  color,
  colors = [
    "#c56cf0",
    "#ffb8b8",
    "#ff3838",
    "#ff9f1a",
    "#fff200",
    "#32ff7e",
    "#7efff5",
    "#18dcff",
  ],
  onChange = () => null,
}: ColorPickerProps) {
  const cssColor = useColor(color);
  return (
    <div onClick={(e) => e.stopPropagation()} className="flex items-center">
      <Popover>
        <PopoverTrigger>
          <ColorSwatch color={cssColor ?? 'transparent'} className="w-4 h-4 mx-1"></ColorSwatch>
        </PopoverTrigger>
        <PopoverContent>
          <div className="space-y-2">
            <HexColorPicker
              style={{
                width: "100%",
              }}
              color={cssColor}
              onChange={(newValue) => onChange(newValue)}
            />
            <div>
              <div className="label">Presets: </div>
              <ToggleGroup.Root
                type="single"
                value={cssColor}
                onValueChange={(color) => color && onChange(color)}
                aria-label="Color"
                className="flex items-center jusity-center space-x-1"
              > 
                <ToggleGroup.Item value={'#ffffff00'} aria-label={color}>
                  <div className="relative h-6 w-6 border-0 bg-white text-red-500 flex items-center justify-center overflow-hidden"><XIcon size={30} /></div>
                </ToggleGroup.Item>
                {colors.map((color) => (
                  <ToggleGroup.Item key={color} value={color} aria-label={color}>
                    <ColorSwatch color={color} className="h-6 w-6 border-0" />
                  </ToggleGroup.Item>
                ))}
              </ToggleGroup.Root>
            </div>
            <HexColorInput
              className="input"
              prefixed
              color={cssColor}
              onChange={(newValue) => onChange(newValue)}
            />
          </div>
        </PopoverContent>
      </Popover>
      <HexColorInput
        className="input w-16"
        prefixed
        color={cssColor}
        onChange={(newValue) => onChange(newValue)}
      />
    </div>
  );
}