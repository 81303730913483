import { createSlice, createEntityAdapter } from "@reduxjs/toolkit";
import { fetchMaps } from "./mapFetch";
import { forEach } from "lodash-es";
import proj4, { refreshProjections } from "../lib/geo/proj4";

const projectionsAdapter = createEntityAdapter<QM.Projection>();

export const slice = createSlice({
  name: "projections",
  initialState: projectionsAdapter.getInitialState(),
  reducers: {
    layerUpdated: projectionsAdapter.updateOne,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMaps.fulfilled, (state, action) => {
      // @ts-ignore
      const projections = action?.payload?.entities?.projections;

      if (projections) {
        forEach(projections, (val, key) => {
          // TODO: Remove this specialcase
          if (val.proj.includes("ob_tran")) {
            val.proj = "+proj=longlat +a=1737400 +b=1737400 +no_defs";
            val.extent = [-180, -90, 180, 90];
          }
          proj4.defs(val.code, val.proj);
        });
        refreshProjections();
        projectionsAdapter.upsertMany(state, projections);
      }
    });
  },
});

type ProjectionsSlice = {
  [slice.name]: ReturnType<typeof slice["reducer"]>;
};

export const {
  selectById: selectProjectionById,
  selectIds: selectProjectionIds,
  selectEntities: selectProjectionEntities,
  selectAll: selectAllProjections,
  selectTotal: selectTotalProjections,
} = projectionsAdapter.getSelectors<ProjectionsSlice>(
  (state) => state.projections
);

export const actions = slice.actions;

const reducer = slice.reducer;
export default reducer;
