import { every, filter, isEmpty, shuffle, some } from "lodash-es";
import React, { useMemo } from "react";
import { memoize } from "proxy-memoize";
import { useAppDispatch, useAppSelector } from "../hooks";

import {
  setLayerLoadError,
} from "../slices/layerLoad";
import { selectLayerById } from "../slices/layers";
import { fuzzySearch } from "./utils/hooks";
import { EntityId } from "@reduxjs/toolkit";
import { filterUndefObj, isDefined } from "../lib/utils";
import {emptyFeatureCollection, bbox as getBbox, coordAll} from "../lib/geo/formats/geojson";
import { getGeoJSONQuery, useGeoJSON } from "./hooks/useGeoJSON";


const palette = shuffle([
  [85, 239, 196, 1.0],
  [129, 236, 236, 1.0],
  [116, 185, 255, 1.0],
  [162, 155, 254, 1.0],
  [0, 184, 148, 1.0],
  [0, 206, 201, 1.0],
  [9, 132, 227, 1.0],
  [108, 92, 231, 1.0],
  [255, 234, 167, 1.0],
  [250, 177, 160, 1.0],
  [255, 118, 117, 1.0],
  [253, 121, 168, 1.0],
]);

let counter = 0;
export const getStyle = () => {
  const stroke = palette[counter++ % palette.length];
  const fill = [stroke[0], stroke[1], stroke[2], 0.3];

  return {
    stroke,
    fill,
    "stroke-width": 2,
    "marker-size": 3,
    //"markerColor": stroke,
  };
};

const COMMON_LABEL_FIELDS = ["title", "label", "name", "site"];
const getDefaultLabelField = (properties: string[]) => {
  for (const testLabel of COMMON_LABEL_FIELDS) {
    for (const property of properties) {
      const normalizedProperty = property.trim().toLowerCase();
      if (
        testLabel === normalizedProperty ||
        normalizedProperty.includes(testLabel)
      ) {
        return property;
      }
    }
  }
};

export function getGeoJSONInfo(geojson: GeoJSON.FeatureCollection) {
  const availableProperties: Record<string, Set<any>> = {};
  geojson?.features?.forEach((f) => {
    for (const key in f.properties) {
      if (!availableProperties[key]) {
        availableProperties[key] = new Set();
      }
      availableProperties[key].add(f.properties[key]);
    }
  });
  const labelProperty = getDefaultLabelField(Object.keys(availableProperties));
  let searchFields: string[] = [];
  if (labelProperty) {
    searchFields = [labelProperty];
  }
  let bbox: GeoJSON.BBox | undefined = getBbox(geojson);
  if (!bbox.every(isFinite)) {
    bbox = undefined;
  }
  return {
    availableProperties,
    labelProperty,
    searchFields,
    hasHeight: coordAll(geojson)?.[0]?.length === 3,
    bbox,
    style: filterUndefObj({ ...getStyle(), labelProperty})
  };
};
export type GeoJSONInfo = ReturnType<typeof getGeoJSONInfo>;


const defaultKeys = ["NAME"];

const filterGeoJSON = memoize(
  ({
    geojson,
    layerProps,
  }: {
    geojson: GeoJSON.FeatureCollection;
    layerProps: Partial<QM.LayerLayer>;
  }): GeoJSON.FeatureCollection => {
    const { filters, searchFilters, options = {} } = layerProps ?? {};
    const { searchFields = defaultKeys } = options;
    let filteredGeoJSON = geojson;
    if (filters && !every(filters, isEmpty)) {
      filteredGeoJSON = {
        ...geojson,
        features: filter(geojson.features, (feature) =>
          every(filters, (values, field) =>
            some(values, (val) => feature.properties?.[field] === val)
          )
        ),
      };
    }
    if (!searchFilters) return filteredGeoJSON;
    const features =
      fuzzySearch({
        search: searchFilters,
        items: filteredGeoJSON?.features ?? [],
        options: {
          shouldSort: true,
          threshold: 0.3,
          location: 0,
          distance: 100,
          minMatchCharLength: 1,
          keys: searchFields.map((x) => `properties.${x}`),
        },
        maxSize: Infinity,
      }) ?? [];
    return {
      ...geojson,
      features,
    };
  },
  { size: 500 }
);

export const memoizedGetGeoJSONInfo = memoize(
  ({ geojson }: { geojson: GeoJSON.FeatureCollection | undefined }) => {
    if (!geojson) return;
    return getGeoJSONInfo(geojson);
  },
  { size: 500 }
);

const mergeGeoJSONLayerOptions = memoize(
  ({
    info,
    layerOptions,
    layerStyle,
  }: {
    info?: GeoJSONInfo;
    layerOptions: any;
    layerStyle: any;
  }) => {
    if (!isDefined(info)) return;
    return filterUndefObj({
      options: {
        searchFields: info.searchFields,
        ...layerOptions,
        cesiumLayer: {
          ...layerOptions?.cesiumLayer,
          clampToGround: !info.hasHeight,
        },
      },
      style: { ...info.style, ...layerStyle },
      defaultExtent: info.bbox,
      clampToGround: !info.hasHeight,
    });
  },
  { size: 500 }
);
export function useFilteredGeoJSON(
  geojson: GeoJSON.FeatureCollection | undefined,
  layerProps?: Partial<QM.LayerLayer> | null
) {
  if (!geojson || !layerProps) return;
  return filterGeoJSON({ geojson, layerProps });
}
export function useGeoJSONInfo(
  geojson: GeoJSON.FeatureCollection | undefined
) {
  if (!geojson) return;
  return memoizedGetGeoJSONInfo({ geojson });
}

export function getLayerURLPath(layer?: Partial<QM.LayerLayer> | null) {
  let url = layer?.url;
  const baseUrl = layer?.source?.server?.urls?.[0];
  const path = layer?.source?.path;
  if (baseUrl && path) {
    url = `${baseUrl}${path}`;
  }
  return url;
}
export function getLayerGeoJSONQuery(layer?: Partial<QM.LayerLayer> | null) {
  const url = getLayerURLPath(layer);
  return getGeoJSONQuery(url);
}
export function useLayerGeoJSON(layer?: Partial<QM.LayerLayer> | null) {
  const dispatch = useAppDispatch();

  const results = useGeoJSON(layer?.type === 'vector' ? getLayerURLPath(layer) : undefined, undefined, {
    layerId: layer?.id,
  });
  const geojsonInfo = memoizedGetGeoJSONInfo({ geojson: results.data });

  if (results.error) {
    dispatch(setLayerLoadError(layer?.id));
  }
  const layerProps = useMemo(() => {
    return mergeGeoJSONLayerOptions({
      info: geojsonInfo,
      layerOptions: layer?.options,
      layerStyle: layer?.style,
    });
  }, [layer?.options, layer?.style, geojsonInfo]);

  return {
    ...results,
    geojson: results.data ?? emptyFeatureCollection,
    filteredGeoJSON:
      useFilteredGeoJSON(results.data, layer) ?? emptyFeatureCollection,
    layerProps,
    info: geojsonInfo
  };
}

export function useLayerGeoJSONByID(layerId?: EntityId | null) {
  const layer = useAppSelector((state) => {
    if (!layerId) return;
    if (layerId === 'user-defined') return userDefinedVectorLayer;
    const l = selectLayerById(state, layerId);
    if (l?.type !== "vector") return;
    return l;
  });
  return useLayerGeoJSON(layer);
}

export const userDefinedVectorLayer: Partial<QM.LayerLayer> = {
  type: "vector",
  id: "user-defined",
  url: 'user-defined',
  options: {},
  style: {
    "labelProperty": "label"
  }
};

export default function fetchGeoJSON<P extends object>(
  Component: React.ComponentType<P>,
  useFiltered = false
) {
  return function (props: P) {
    const { geojson, filteredGeoJSON, layerProps } = useLayerGeoJSON(props);

    //@ts-ignore
    if (props?.type !== 'vector') return <Component {...props} />;
    return (
      <Component
        {...props}
        geojson={useFiltered ? filteredGeoJSON : geojson}
        filteredGeoJSON={filteredGeoJSON}
        {...layerProps}
      />
    );
  };
}
