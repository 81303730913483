import React from "react";

import { useOLContext } from "../OlContext";
import { useTileSource, useOlLayer, useProjectedExtent } from "../olAdapter";
import WebGLTileLayer from "ol/layer/WebGLTile";
import DataTileSource from "ol/source/DataTile";

import { PALETTES } from "../../../lib/palettes";
import {
  UseDynamicLayerOptionsReturnType,
  useACTLayerURL,
  useDynamicLayerOptions,
  useLayerLoadingCallbacks,
} from "../../layerHooks";
import { OlLayerProps } from ".";
import { Projection } from "ol/proj";
import { Expression } from "ol/expr/expression";
import { useAppSelector } from "src/hooks";
import { memoize } from "proxy-memoize";
import { isDefined } from "src/lib/utils";
import { Color } from "@cesium/engine";
import { isArray } from "lodash-es";

import type { Style } from "ol/layer/WebGLTile";
export function calculateZoomLevelsForProjection(
  maxResolution: number = -1,
  minResolution: number = -1,
  projection: Projection,
  tileSize: number = 512
) {
  let minZoom, maxZoom;
  if (!isDefined(projection)) return { minZoom, maxZoom };
  const extent = projection.getExtent();
  const dim = Math.max(
    Math.abs(extent[0] - extent[2]),
    Math.abs(extent[1] - extent[3])
  );
  if (maxResolution > 0) {
    minZoom = Math.floor(Math.log2(dim / (tileSize * maxResolution)));
  }
  if (minResolution > 0) {
    maxZoom = Math.ceil(Math.log2(dim / (tileSize * minResolution)));
  }
  return {
    minZoom,
    maxZoom,
  };
}

export const getCalculateZoomLevelsForProjection = memoize(
  ({
    maxResolution,
    minResolution,
    projection,
    tileSize,
  }: {
    maxResolution?: number;
    minResolution?: number;
    projection: Projection;
    tileSize?: number;
  }) =>
    calculateZoomLevelsForProjection(
      maxResolution,
      minResolution,
      projection,
      tileSize
    )
);
export function createFLTLoader(inputUrl: string) {
  const urlObj = new URL(inputUrl);
  urlObj.searchParams.set("size", "256");
  const url = decodeURI(urlObj.toString());
  return async function fltTileLoader(z: number, x: number, y: number) {
    const tileUrl = url
      .replace("{z}", `${z}`)
      .replace("{x}", `${x}`)
      .replace("{y}", `${y}`);
    const res = await fetch(tileUrl);
    const data = await res.arrayBuffer();
    const [nrows, ncols] = new Uint32Array(data.slice(0, 8));
    return new Float32Array(data.slice(9), 0, nrows * ncols);
  };
}

const transparent = [0, 0, 0, 0];
const maxMaskVal = [
  "case",
  ["==", ["var", "shouldMask"], 1],
  ["var", "bMax"],
  Number.MAX_SAFE_INTEGER,
];
const minMaskVal = [
  "case",
  ["==", ["var", "shouldMask"], 1],
  ["var", "bMin"],
  Number.MIN_SAFE_INTEGER,
];

function getStyle(
  palette: string = "wcolut0",
  styles: Expression[] = [],
  sourceBand = 1
) {
  const vals = (PALETTES[palette] ?? PALETTES.wcolut0)?.values;
  const pixelVal = ["band", sourceBand];
  const interpolate = [
    "interpolate",
    ["linear"],
    pixelVal,
    ["var", "bMin"],
    0,
    ["var", "bMax"],
    255,
  ];
  return [
    "case",
    ...styles,
    ["!", ["between", pixelVal, minMaskVal, maxMaskVal]],
    transparent,
    [
      "palette",
      interpolate,
      vals?.map((x: number[]) => [...x, 1]) ?? [[0, 0, 0, 0]],
    ],
  ];
}
function stopsToCase(array: (number | string)[], sourceBand = 1) {
  const steps = [];
  const pixelVal = ["band", sourceBand];
  for (let i = 0; i < array.length - 1; i += 2) {
    steps.push([['<', pixelVal, array[i + 1]], array[i]]);
  }
  steps.push(array[array.length - 1]);
  return [
    "case",
    ["!", ["between", pixelVal, Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER]],
    transparent,
    ...steps.flat(),
  ];
}


//hillshade
function elevation(xOffset: number, yOffset: number) {
  return ['band', 1, xOffset, yOffset];
}

// Generates a shaded relief image given elevation data.  Uses a 3x3
// neighborhood for determining slope and aspect.
const dp = ['*', 2, ['resolution']];
const z0x = ['*', ['var', 'vert'], elevation(-1, 0)];
const z1x = ['*', ['var', 'vert'], elevation(1, 0)];
const dzdx = ['/', ['-', z1x, z0x], dp];
const z0y = ['*', ['var', 'vert'], elevation(0, -1)];
const z1y = ['*', ['var', 'vert'], elevation(0, 1)];
const dzdy = ['/', ['-', z1y, z0y], dp];
const slope = ['atan', ['sqrt', ['+', ['^', dzdx, 2], ['^', dzdy, 2]]]];
const aspect = ['clamp', ['atan', ['-', 0, dzdx], dzdy], -Math.PI, Math.PI];
const sunEl = ['*', Math.PI / 180, ['var', 'bMax']];
const sunAz = ['*', Math.PI / 180, ['var', 'bMin']];

const cosIncidence = [
  '+',
  ['*', ['sin', sunEl], ['cos', slope]],
  ['*', ['cos', sunEl], ['sin', slope], ['cos', ['-', sunAz, aspect]]],
];
const scaled = ['*', 255, cosIncidence];

export function useACTWebGLStyle(
  dynamicOptions: UseDynamicLayerOptionsReturnType,
  customStyles?: any[],
  sourceBand = 1
): Style {
  return React.useMemo<Style>(() => {
    if (dynamicOptions.type === "hillshade") {
      return {
        color: ['color', scaled],
        variables: {
          bMin: +(dynamicOptions.range?.[0] ?? 45),
          bMax: +(dynamicOptions.range?.[1] ?? 315),
          vertEx: 1,
          shouldMask: 0, // not used for this type
        },
      }
    }
    if (isArray(dynamicOptions.rangePalette)) {
      console.log(stopsToCase(dynamicOptions.rangePalette, sourceBand));
      return {
        color: stopsToCase(dynamicOptions.rangePalette, sourceBand),
        variables: {
          bMin: +(dynamicOptions.range?.[0] ?? 0),
          bMax: +(dynamicOptions.range?.[1] ?? 1),
          shouldMask: dynamicOptions.rangeMask ? 1 : 0,
          vertEx: 1 // not used for this type
        },
      }
    }
    if (dynamicOptions.rangePalette.indexOf('#') === 0) {
      const pixelVal = ["band", sourceBand];
      const color = Color.fromCssColorString(dynamicOptions.rangePalette).toBytes();
      color[3] /= 255;
      return {
        color: [
          "case",
          ["==", pixelVal, 1],
          color,
          [0, 0, 0, 0],
        ],
        variables: {
          bMin: dynamicOptions.range?.[0] ?? 0,
          bMax: dynamicOptions.range?.[1] ?? 1,
          shouldMask: dynamicOptions.rangeMask ? 1 : 0,
          vertEx: 1 //not used for this type
        },
      };
    }
    return {
      color: getStyle(dynamicOptions.rangePalette, customStyles, sourceBand),
      variables: {
        bMin: dynamicOptions.range?.[0] ?? 0,
        bMax: dynamicOptions.range?.[1] ?? 1,
        shouldMask: dynamicOptions.rangeMask ? 1 : 0,
        vertEx: 1 //not used for this type
      },
    };
  }, [
    dynamicOptions.rangePalette,
    dynamicOptions.min,
    dynamicOptions.max,
    customStyles,
  ]);
}

export default function ACTWebGLLayerLoader(
  props: OlLayerProps & { params?: { [key: string]: any } }
) {
  const layerLoadFns = useLayerLoadingCallbacks(props.id);
  const fakePolarShifted = useAppSelector(
    (state) => state.mapState.__debugFakePolarShifted
  );

  const url = useACTLayerURL(
    {
      ...props,
      ignoreDynamicParams: true,
      imageFormat: "flt",
    },
    fakePolarShifted
  );
  const loader = React.useMemo(() => createFLTLoader(url), [url]);
  const { projection, map } = useOLContext();
  // abort rendering if map has no dom assigned
  if (!map?.getTarget()) return;
  return (
    <ACTWebGLLayer
      {...props}
      {...layerLoadFns}
      extent={props.projectionObj?.adjExtent}
      flicker={props.flicker}
      loader={loader}
      projection={projection}
    />
  );
}
type layerLoadFn = () => void;
function ACTWebGLLayer({
  onLoadStart,
  onLoadEnd,
  onLoadError,
  flicker,
  loader,
  projection,
  sources, //not used by ACTWebGLLayer
  ...props
}: OlLayerProps & {
  loader: (z: number, x: number, y: number) => Promise<Float32Array>;
  projection: Projection;
  extent: number[];
  onLoadEnd: layerLoadFn;
  onLoadStart: layerLoadFn;
  onLoadError: layerLoadFn;
}) {
  const zooms = calculateZoomLevelsForProjection(
    props.resolutions?.[0],
    props.resolutions?.[1],
    projection,
    256
  );
  const source = useTileSource(DataTileSource, {
    ...props,
    loader,
    onLoadEnd,
    onLoadStart,
    onLoadError,
    bandCount: 1,
    tileSize: 256,
    projection: projection,
    ...zooms,
  });
  const dynamicOptions = useDynamicLayerOptions(props);
  const customStyles = props?.options?.dynamic?.styles;
  const style = useACTWebGLStyle(dynamicOptions, customStyles);

  const extent = useProjectedExtent(props.defaultExtent ?? undefined);

  const layer = useOlLayer(WebGLTileLayer, {
    ...props,
    source,
    style,
    flicker,
    extent: extent ?? undefined,
  });

  React.useEffect(() => {
    if (!dynamicOptions.range) return;
    //@ts-ignore
    const vars = layer?.style_.variables;
    if (
      vars.bMin !== dynamicOptions.range?.[0] ||
      vars.bMax !== dynamicOptions.range?.[1] ||
      vars.shouldMask !== dynamicOptions.rangeMask
    ) {
      layer?.updateStyleVariables({
        bMin: dynamicOptions.range?.[0] ?? 0,
        bMax: dynamicOptions.range?.[1] ?? 1,
        shouldMask: dynamicOptions.rangeMask ? 1 : 0,
        vert: 1
      });
    }
  }, [layer, dynamicOptions.range, dynamicOptions.rangeMask, dynamicOptions.rangePalette]);

  return null;
}
