import { configureStore } from "@reduxjs/toolkit";

import { default as reducer } from "./reducers";
const middlewares: any[] = [];

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }).concat(middlewares),
    devTools: !import.meta.env.PROD
  });
export default store;
  // Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
