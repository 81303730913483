import React from "react";
import { uniqBy, map, sortBy, isEmpty } from "lodash-es";
import { EntityId } from "@reduxjs/toolkit";
import { XIcon } from "lucide-react";

import { useAppDispatch } from "src/hooks";
import { filterUndef } from "src/lib/utils";
import { MultiSelect } from "src/components/base/MultiSelect";
import { useLayerGeoJSONByID } from "src/components/fetchGeoJSON";
import { useFeatureInExtent } from "src/components/mapHooks";
import { Button } from "src/components/base/Button";
import Tooltip from "src/components/base/Tooltip";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/base/Select";
import { actions as LayerActions } from "src/slices/layers";
import ColorPicker, { ColorInput } from "src/components/ColorPicker";
import { IS_RELEASE } from "src/constants";

const DEFAULT_FILTER_FIELDS = ["TYPE"];
const DEFAULT_SEARCH_FIELDS = ["NAME"];

type FeatureSearchResultItemProps = {
  feature: GeoJSON.Feature;
  labelProperty: string;
  onZoomToFeature: (feature: GeoJSON.Feature) => void;
};
function FeatureSearchResultItem({
  feature,
  labelProperty,
  onZoomToFeature,
}: FeatureSearchResultItemProps) {
  const isInExtent = useFeatureInExtent(feature);
  const tooltip = !isInExtent
    ? "Feature is not visible in this projection"
    : null;
  return (
    <Tooltip content={tooltip} open={tooltip ? undefined : false}>
      <li
        className={`cursor-pointer hover:bg-qmprimarylight ${
          !isInExtent && "pseudo-disabled"
        }`}
        key={feature.properties?.[labelProperty]}
        onClick={() => isInExtent && onZoomToFeature(feature)}
      >
        <span>{feature.properties?.[labelProperty]}</span>
      </li>
    </Tooltip>
  );
}

type LayerFeaturesProps = {
  id: EntityId;
  filterFields?: string[];
  filters: Record<string, string[]>;
  searchFilters: string;
  searchFields?: string[];
  onSearchUpdate: (val?: string) => void;
  onFilterFeatureChange: (field: string, val: string[]) => void;
  onZoomToFeature: (feature: GeoJSON.Feature) => void;
};
export default function LayerFeatures(props: LayerFeaturesProps) {
  const {
    filterFields = DEFAULT_FILTER_FIELDS,
    filters,
    searchFilters,
    onSearchUpdate,
    onFilterFeatureChange,
    onZoomToFeature,
    id,
  } = props;
  const dispatch = useAppDispatch();
  const [count, setCount] = React.useState(20);
  const { geojson, filteredGeoJSON, layerProps, info, actions } =
    useLayerGeoJSONByID(props.id);
  const { searchFields = DEFAULT_SEARCH_FIELDS } = layerProps?.options ?? {};
  const types = React.useMemo(() => {
    const filterField = filterFields[0];
    // @ts-ignore
    const uniqueTypes = uniqBy(geojson.features, `properties.${filterField}`);
    const result = sortBy(
      map(uniqueTypes, (x) => {
        if (x.properties?.[filterField] == undefined) {
          return undefined;
        }
        return {
          value: x.properties[filterField],
          label: `${x.properties[filterField]}`,
        };
      }),
      "label"
    );
    if (result?.filter(Boolean)?.length === 0) return null;
    return result;
  }, [filterFields, geojson]);

  const placeholder = `Filter by ${filterFields?.[0]?.toLowerCase()}...`;

  let searchResults;

  const hasSearchProperty = geojson?.features?.some(
    (x) => x?.properties?.[searchFields?.[0]]
  );
  if ((searchFilters || filters?.[0]?.length) && filteredGeoJSON) {
    const results = filteredGeoJSON.features;
    if (!results.length) {
      searchResults = <div>No results for query</div>;
    }
    const items = results.slice(0, count || 20);
    searchResults = (
      <ul>
        {items.map((res) => (
          <FeatureSearchResultItem
            feature={res}
            labelProperty={searchFields[0]}
            onZoomToFeature={onZoomToFeature}
          />
        ))}
        {results.length > items.length ? (
          <Tooltip content="Clear search">
            <Button
              size="sm"
              variant="secondary"
              isQuiet
              onPress={() => setCount((c) => c + 20)}
            >
              Show more...
            </Button>
          </Tooltip>
        ) : null}
      </ul>
    );
  }
  return (
    <div>
      {!IS_RELEASE && <><h3>Style</h3>
      <div className="mb-2">
        <label className="label">Label Property</label>
        <Select
          value={layerProps?.style?.labelProperty}
          onValueChange={(value) =>
            dispatch(
              LayerActions.mergeUpdateLayerStyle(id, {
                labelProperty: value === "null" ? undefined : value,
              })
            )
          }
        >
          <SelectTrigger>
            <SelectValue placeholder={"Select label"} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem key={`null`} value={"null"}>
              (None)
            </SelectItem>
            {Object.keys(info?.availableProperties ?? {}).map(
              (prop) =>
                !isEmpty(prop) && (
                  <SelectItem key={prop} value={prop}>
                    {prop}
                  </SelectItem>
                )
            )}
          </SelectContent>
        </Select>
        <div className="mb-2">
          <label className="label">Fill Color</label>
          <ColorInput
            color={layerProps?.style?.fill}
            onChange={(value) =>
              dispatch(
                LayerActions.mergeUpdateLayerStyle(id, {
                  fill: value,
                })
              )
            }
          />
        </div>
        <div className="flex flex-row items-center space-x-4">
          <div className="mb-2">
            <label className="label">Stroke Color</label>
            <ColorInput
              color={layerProps?.style?.stroke}
              onChange={(value) =>
                dispatch(
                  LayerActions.mergeUpdateLayerStyle(id, {
                    stroke: value,
                  })
                )
              }
            />
          </div>
          <div className="mb-2">
            <label className="label">Stroke Width</label>
            <input
              type="number"
              value={layerProps?.style?.["stroke-width"] ?? ""}
              onChange={(e) =>
                dispatch(
                  LayerActions.mergeUpdateLayerStyle(id, {
                    "stroke-width": Number(e.target.value),
                  })
                )
              }
              className="input w-10"
            />
          </div>
        </div>
        <div className="flex flex-row items-center space-x-4">
          <div className="mb-2">
            <label className="label">Marker Color</label>
            <ColorInput
              color={layerProps?.style?.["marker-color"]}
              onChange={(value) =>
                dispatch(
                  LayerActions.mergeUpdateLayerStyle(id, {
                    "marker-color": value,
                  })
                )
              }
            />
          </div>

          <div className="mb-2">
            <label className="label">Marker Size</label>
            <input
              type="number"
              value={layerProps?.style?.["marker-size"] ?? ""}
              onChange={(e) =>
                dispatch(
                  LayerActions.mergeUpdateLayerStyle(id, {
                    "marker-size": Number(e.target.value),
                  })
                )
              }
              className="input w-10"
            />
          </div>
        </div>
      </div></>}
      <h3>Filter</h3>
      <div className="my-1">
        Showing {filteredGeoJSON?.features?.length ?? 0} of{" "}
        {geojson?.features?.length ?? 0} features
      </div>

      {types && types.length && (
        <MultiSelect
          options={filterUndef(types)}
          value={filters?.[filterFields[0]]}
          onValueChange={(vals) => {
            onFilterFeatureChange(filterFields[0], vals);
          }}
          placeholder={placeholder}
        />
      )}
      {hasSearchProperty && (
        <form
          onSubmit={(e) => e.preventDefault()}
          className="my-3 flex flex-row items-center space-x-1"
        >
          <input
            type="text"
            placeholder="Filter by name"
            value={searchFilters ?? ""}
            onChange={(e) => onSearchUpdate(e.target.value)}
            style={{
              padding: "4px 12px",
              color: "#ecf0f1",
              display: "inline-block",
            }}
          />
          {searchFilters?.[0] && filteredGeoJSON?.features && (
            <Tooltip content="Clear search">
              <Button
                size="sq"
                variant="secondary"
                isQuiet
                onPress={() => onSearchUpdate(undefined)}
              >
                <XIcon size={16} />
              </Button>
            </Tooltip>
          )}
        </form>
      )}
      <div>{searchResults}</div>
    </div>
  );
}
