//@ts-nocheck disable third-party lib checking for now
import React from "react";
import { range, omitBy } from "lodash-es";
import { prefix } from "inline-style-prefixer";

function animate({
  delay = undefined,
  duration = undefined,
  fillMode = undefined,
  iterationCount = undefined,
  name = undefined,
  timingFunction = undefined,
}) {
  if (name) {
    /* eslint-disable no-param-reassign */

    /*
     *  don't apply defaults unless we have a name declaration
     *  otherwise, assume we're using this to override properties
     */

    duration = duration || "1.2s";
    iterationCount = iterationCount || "infinite";
    timingFunction = timingFunction || "ease-in-out";

    /* eslint-enable no-param-reassign */
  }

  return prefix(
    omitBy(
      {
        animationDelay: delay,
        animationDuration: duration,
        animationFillMode: fillMode,
        animationIterationCount: iterationCount,
        animationTimingFunction: timingFunction,
        animationName: name,
      },
      (val) => !val
    )
  );
}

function animationName(name = "spinner") {
  return `brsk-${name}`;
}
function Base({ css, children, ...props }) {
  return (
    <span {...props}>
      <style children={css} />
      {children}
    </span>
  );
}

const Wave = ({
  color = "#2980b9",
  columns = 3,
  columnWidth = "20%",
  gutterWidth = 1,
  reverse = true,
  scaleYEnd = 1,
  scaleYStart = 0.3,
  size = 20,
  ...props
}) => {
  const name = animationName("wave");
  const outer = {
    display: "flex",
    justifyContent: "space-between",
    height: size - size * 0.2,
    width: size,
  };
  const column = {
    ...animate({ name }),
    backgroundColor: color,
    height: "100%",
    width: columnWidth,
  };
  const cols = reverse ? range(columns).reverse() : range(columns);
  return (
    <Base
      css={`
        @-webkit-keyframes ${name} {
          0%,
          40%,
          100% {
            -webkit-transform: scaleY(${scaleYStart});
          }
          20% {
            -webkit-transform: scaleY(${scaleYEnd});
          }
        }
        @keyframes ${name} {
          0%,
          40%,
          100% {
            transform: scaleY(${scaleYStart});
            -webkit-transform: scaleY(${scaleYStart});
          }
          20% {
            transform: scaleY(${scaleYEnd});
            -webkit-transform: scaleY(${scaleYEnd});
          }
        }
      `}
      {...props}
    >
      <div style={outer}>
        {cols.map((index) => (
          <div
            key={index}
            style={{
              ...column,
              ...animate({ delay: `-${index / 10}s` }),
              marginRight: index !== columns ? gutterWidth : 0,
            }}
          />
        ))}
      </div>
    </Base>
  );
};

export default Wave;
