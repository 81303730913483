import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
//@ts-ignore
import cookie from "component-cookie";
import { fetchAPI } from "../api";

function loadUser() {
  return cookie("user") ? JSON.parse(cookie("user")) : {};
}

function saveUser(obj?: object | null) {
  if (!obj) return cookie("user", null);
  cookie("user", JSON.stringify(obj), {
    maxage: 14 * 24 * 60 * 60 * 1000, // 2 weeks
  });
}

export type UserLogin = { username: string; password: string };
export const login = createAsyncThunk(
  "user/login",
  async (user: UserLogin, { rejectWithValue }) => {
    const res = await fetchAPI("api-token-auth/", null, {
      method: "POST",
      body: JSON.stringify(user),
    });
    return res;
  }
);

const loggedOutState = {
  username: null,
  token: null,
  isLoading: false,
  error: null,
};

const initialState = {
  ...loggedOutState,
  ...loadUser(),
};
const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout(state, action) {
      saveUser(null);
      return {
        ...loggedOutState,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        saveUser(null);
        return {
          ...initialState,
          isLoading: true,
        };
      })
      .addCase(login.fulfilled, (state, action) => {
        saveUser(action.payload);
        return {
          ...initialState,
          ...action.payload,
        };
      })
      .addCase(login.rejected, (state, action) => {
        saveUser(null);
        let error = action?.error?.message;
        if (error && isJsonString(error)) {
          error = JSON.parse(error);
        }
        return {
          ...initialState,
          error,
        };
      });
  },
});
export default user.reducer;
export const { logout } = user.actions;

function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
