import GeoJSON from "ol/format/GeoJSON";
import {
  reduce,
  every,
  filter,
  isFinite,
  isArray as _isArray,
  isNil,
  get,
  uniqBy,
} from "lodash-es";
import * as olExtent from "ol/extent";
import * as olProj from "ol/proj";
import Feature from "ol/Feature";
import { FeatureCollection, Position } from "geojson";
import { isDefined } from "../../utils";

export { default as bbox } from "@turf/bbox";
export { default as bboxPolygon } from "@turf/bbox-polygon";
//@ts-expect-error
export { default as normalize } from "@mapbox/geojson-normalize";
export { default as truncate } from "@turf/truncate";

export { coordAll } from "@turf/meta";
const formatter = new GeoJSON();

const featureIsValid = (f: Feature) => every(f?.getGeometry()?.getExtent(), isFinite);

export const emptyFeatureCollection = { type: "FeatureCollection", features: [] } as GeoJSON.FeatureCollection;

const nullCollection = formatter.readFeatures(emptyFeatureCollection) as Feature[];

function filteredCoords<T extends Positions>(coordsArray: T): T {
  return reduce(
    coordsArray,
    (acc, value) => {
      if (!value) return acc;
      if (_isArray(value) && every(value, isFinite)) {
        acc.push(value);
      } else if (_isArray(value) && every(value, _isArray)) {
        acc.push(filteredCoords(value));
      } else if (isFinite(value)) {
        acc.push(value);
      }
      return acc;
    },
    [] as any[]
  ) as T;
}



/**
 * Converts geojson to [ol.Feature]
 * Ensures reprojected properly and any null features removed
 */
export const toOlFeatures = (
  geojson: GeoJSON.Feature | GeoJSON.FeatureCollection | null | undefined,
  dataProjection?: olProj.ProjectionLike,
  featureProjection?: olProj.ProjectionLike,
  clipExtent?: olExtent.Extent
) => {
  const opts = {
    dataProjection,
    featureProjection,
  };
  if (!geojson || !geojson.type) return nullCollection;

  let features: Feature[];
  try {
    features = formatter.readFeatures(geojson, opts) as Feature[];
  } catch (e) {
    console.error("Error reading geojson features", e, opts);
    return;
  }
  let toLonLatTransform: olProj.TransformFunction = olProj.identityTransform;
  try {
    if (featureProjection && dataProjection)
      toLonLatTransform = olProj.getTransform(
        featureProjection,
        dataProjection
      );
  } catch (e) {
    console.error("Error reading geojson features", e, opts);
    return;
  }

  features.forEach((f, i) => {
    const geom = f.getGeometry();
    if (!geom) return;

    //@ts-ignore
    const coordinates = geom?.getCoordinates();
    if (geom.getType() === "Point") return;
    const filtered = filteredCoords(coordinates);
    //@ts-ignore
    geom?.setCoordinates(filtered);
  });

  const clipToValidExtent = clipExtent
    ? (f: Feature) => {
        if (!featureIsValid(f)) return false;
        const extent = f.getGeometry()?.getExtent();
        if (!extent) return false;
        const ll_extent = olExtent.applyTransform(extent, toLonLatTransform);
        const isIntersecting = olExtent.intersects(ll_extent, clipExtent);
        return isIntersecting;
      }
    : featureIsValid;

  return filter<Feature>(features, clipToValidExtent);
};

export const fromOlFeatures = (
  features: Feature[],
  dataProjection: olProj.ProjectionLike,
  featureProjection: olProj.ProjectionLike
) =>
  formatter.writeFeaturesObject(features, {
    dataProjection,
    featureProjection,
  });
type Positions = Position | Position[] | Position[][] | Position[][][];

export function normalizeLongitude(
  coordinate: GeoJSON.Position,
  is360 = false
): GeoJSON.Position {
  let val = ((coordinate[0] + 540) % 360) - 180;
  if (is360 && val < 0) val = ((val % 180) + 360)
  return replaceAt(coordinate, 0, val);
}
export const wrapCoords = normalizeLongitude;
function replaceAt(array: any[], index: number, value: any) {
  const ret = array.slice(0);
  ret[index] = value;
  return ret;
}

export function to360(coordinate: GeoJSON.Position) {
  if (!Array.isArray(coordinate)) return coordinate;
  const lon = coordinate[0];
  if (lon < 0 || lon > 360) {
    return normalizeLongitude(coordinate, true);
  }
  return coordinate;
}
export function from360(coordinate: GeoJSON.Position) {
  if (!Array.isArray(coordinate)) return coordinate;
  const lon = coordinate[0];
  if (Math.abs(lon) > 180) {
    return normalizeLongitude(coordinate);
  }
  return coordinate;
}

export const isPositionArray = (arr: any): arr is Position[] => arr.every(isValidCoordinate);
export const isValidCoordinate = (coord: any): coord is Position =>
  Array.isArray(coord) &&
  (coord.length === 2 || coord.length === 3) &&
  every(coord, isFinite);

export function applyFuncToCoords<T extends Positions>(
  arr: T,
  fn: (position: Position) => Position
): T {
  if (!isDefined(arr) || !Array.isArray(arr)) return arr;
  if (isValidCoordinate(arr)) return fn(arr) as T;
  // @ts-ignore subarr can't be type number because of above check
  return arr.map((subarr) => applyFuncToCoords(subarr, fn)) as T;
}

export function flipLonLat<T extends Positions>(arr: T) {
  return applyFuncToCoords(arr, (coord) =>
    [coord[1], coord[0]].concat(coord.slice(2))
  );
}

export function coordsNormalizeLongitude<T extends Positions>(arr: T, is360: boolean = false): T {
  return applyFuncToCoords(arr, (v) => normalizeLongitude(v, is360));
}
export function coordsTo360<T extends Positions>(arr: T): T {
  return applyFuncToCoords(arr, to360);
}
export function coordsFrom360<T extends Positions>(arr: T): T {
  return applyFuncToCoords(arr, from360);
}

export function addIDToGeoJSONFeatureCollection(geojson: FeatureCollection, idField?: string): FeatureCollection {
  if (!geojson) return geojson;
  if (geojson.type === "FeatureCollection" && isNil(geojson?.features?.[0]?.id)) {
    let idAccessor: string;
    if (idField) {
      const isUnique = uniqBy(geojson?.features, `properties.${idField}`).length === geojson?.features?.length;
      if (!isUnique) {
        console.log(`Field '${idField}' not unique for geojson, falling back to index id`);
      } else {
        idAccessor = `properties.${idField}`;
      }
    }
    return {
      ...geojson,
      features:  geojson.features.map((feature, index) => {
        let id = index;
        if (idAccessor) {
          id = get(feature, idAccessor);
        }
        return {
          ...feature,
          id
        }
      })
    }
  }
  return geojson;
}
