import { EntityId, createSlice } from "@reduxjs/toolkit";

interface LayerLoad {
  isLoading?: boolean
  errors?: boolean
}
interface LayerLoadState {
  loadingByLayerId: { [index: EntityId]: LayerLoad }
}

const initialState: LayerLoadState = {
  loadingByLayerId: {},
};

const layers = createSlice({
  name: "layerLoad",
  initialState,
  reducers: {
    setLayerLoad(state, action) {
      if (action.payload == null) return;
      state.loadingByLayerId[action.payload] = { isLoading: true };
    },
    setLayerLoadEnd(state, action) {
      if (action.payload == null) return;
      state.loadingByLayerId[action.payload] = { isLoading: false };
    },
    setLayerLoadError(state, action) {
      if (action.payload == null) return;

      state.loadingByLayerId[action.payload] = { errors: true };
    },

  },
});

export default layers.reducer;
export const {
  setLayerLoad,
  setLayerLoadEnd,
  setLayerLoadError,
} = layers.actions;
