import { createSlice } from '@reduxjs/toolkit';

export const firstPersonViewSlice  = createSlice({
  name: 'firstPersonView',
  initialState: {
    FPVEnabled: false,
  },
  reducers: {
    setFPVEnabled(state, action) {
        state.FPVEnabled = action.payload;
    },
    toggleFPV(state) {
        state.FPVEnabled = !state.FPVEnabled;
    },
  },
});

export const { setFPVEnabled, toggleFPV } = firstPersonViewSlice.actions;

export default firstPersonViewSlice .reducer;
