import { createSlice } from '@reduxjs/toolkit';

export const mapSceneImagerySyncSlice = createSlice({
  name: 'mapSceneImagerySync',
  initialState: {
    enabled: true,
  },
  reducers: {
    setImagerySyncEnabled(state, action) {
        state.enabled = action.payload;
      },
  },
});

export const { setImagerySyncEnabled } = mapSceneImagerySyncSlice.actions;

export default mapSceneImagerySyncSlice.reducer;