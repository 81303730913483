import mobile from "is-mobile";
import qs from "qs";
import { Cartesian3 } from "@cesium/engine";

const { debug } = qs.parse(window.location.search);

console.log("ENV: ", import.meta.env);

//@ts-ignore
export const GIT_HASH = __GIT_HASH__;
//@ts-ignore
export const BUILD_TIME = __BUILD_TIME__;

if (GIT_HASH || BUILD_TIME) {
  console.log(`VERSION: ${GIT_HASH}`);
  console.log(`BUILD TIME: ${BUILD_TIME ? new Date(BUILD_TIME) : "n/a"}`);
}
export const IS_RELEASE = import.meta.env.VITE_IS_RELEASE ?? false;
export const IS_RELEASE_LAYERS =
  import.meta.env.VITE_IS_RELEASE_LAYERS ?? false;
export const FCGI_DIR = import.meta.env.VITE_FCGI_DIR ?? "fcgi-bin";

export const API_ROOT =
  import.meta.env.VITE_API_ROOT ?? "https://api.quickmap.io/";

export const BASENAME = import.meta.env.VITE_BASENAME ?? "/";

export const APP_TYPE = import.meta.env.VITE_APP_TYPE ?? "app";
export const MAP_ID: string = import.meta.env.VITE_MAP_ID ?? undefined;

export const MAIN_MENU_LOGO = import.meta.env.VITE_MAIN_MENU_LOGO ?? undefined;

export const IS_MOBILE = mobile();

export const DEBUG_URLS = import.meta.env.VITE_DEBUG_URLS ?? false;

export const DEBUG = !!import.meta.env.VITE_DEBUG_MODE;
/*
let url = `${BASENAME}/Cesium/`;
if (import.meta.env.NODE_ENV === "development") {
  //  url = "/node_modules/cesium/Build/Cesium/";
}
if (window) {
  window["CESIUM_BASE_URL"] = url;
}
*/



export type LightSourceOpts = {
  lightSources: string[];
  observer: string;
  source: string;
  solarDayDuration: number;
}

export type TerrainOpts = {
  baseUrl?: string;
  terrainName?: string;
  polarTerrainName?: string;
  qtsLightOpts?: LightSourceOpts;
  isAutoPolarSwitchingEnabled?: boolean;
}
export const QTS_TERRAIN_OPTS: Record<string, TerrainOpts> = {
  moon: {
    //baseUrl: "https://dem-api.quickmap.io/ctod/tiles/lroc-tiles.quickmap.io/qts_demstack/",
    baseUrl: "https://dem-tiles.b-cdn.net/lunar/qts_demstack/",
    terrainName: "lunar-fulleqc",
    polarTerrainName: "lunar-polarshifted-eqc",
    qtsLightOpts: {
      lightSources: ["SUN", "EARTH"],
      observer: "MOON",
      source: "SUN",
      solarDayDuration: 708.7 * 3600,
    },
  },
  mars: {
    baseUrl: "https://dem-tiles.b-cdn.net/mars2/qts_mars_ds/",
    terrainName: "mars-eqc",
    polarTerrainName: "mars-polarshifted-eqc",
    qtsLightOpts: {
      lightSources: ["SUN", "EARTH"],
      observer: "MARS",
      source: "SUN",
      solarDayDuration: 25 * 3600,
    },
  },
  mercury: {
    baseUrl: "https://dem-tiles.b-cdn.net/mercury2/mdem_stack/",
    terrainName: "mercury-eqc",
    polarTerrainName: "mercury-polarshifted-eqc",
    qtsLightOpts: {
      lightSources: ["SUN", "EARTH"],
      observer: "MERCURY",
      source: "SUN",
      solarDayDuration: 4224 * 3600,
    },
  },
  venus: {
    qtsLightOpts: {
      lightSources: ["SUN"],
      observer: "VENUS",
      source: "SUN",
      solarDayDuration: 2802 * 3600,
    },
  },
};

export const MODEL_OFFSETS: Record<string, Cartesian3> = {
  "https://files.actgate.com/models/artemis3spacesuit.glb": new Cartesian3(0, 0, 1.8),
  "https://files.actgate.com/models/artemis3starship.glb": new Cartesian3(0, 0, 30),
  "https://files.actgate.com/models/nova-rotated.glb": new Cartesian3(0, 0, -1),  // the model is upside down
  "https://files.actgate.com/models/orion_moon_lander.glb": new Cartesian3(0, 0, 6),
  "https://files.actgate.com/models/VIPER.glb": new Cartesian3(0, 0, 2),
  "https://files.actgate.com/models/cube_satellite.glb": new Cartesian3(0, 0, 0.3),
  "https://files.actgate.com/models/rocket.glb": new Cartesian3(0, 0, 2),
}
