import React, { useEffect, useCallback } from "react";
import { registerHandlerForLayer } from "../../map";
import { useVectorLayer, useVectorSource, useOLEvent } from "../../olAdapter";
import VectorSource from "ol/source/Vector";
import type BaseEvent from "ol/events/Event";
import type { OlLayerProps } from "..";
import type VectorImageLayer from "ol/layer/VectorImage";
import type RenderEvent from "ol/render/Event";
import type { Feature } from "ol";
import { StyleLike } from "ol/style/Style";

export type VectorLayerProps = {
  id?: string | number;
  onLoadStart?: (id: string | number) => void;
  onLoadEnd?: (id: string | number) => void;
  onSelect?: (
    selected: Feature[],
    deselected: Feature[],
    layer?: string | number,
    name?: string
  ) => void;
  onModify?: (
    features: Feature[],
    layer?: string | number,
    name?: string
  ) => void;
  editable?: boolean;
  source: VectorSource;
} & VectorSourceLayerProps;
export function VectorLayer({
  onLoadStart,
  onLoadEnd,
  onSelect,
  onModify,
  editable,
  ...props
}: VectorLayerProps) {
  const layer = useVectorLayer(props);

  // Handle Loading Events
  const handleLoadEvent = useCallback(
    (evt: BaseEvent) => {
      const state = evt.target.getState();
      if (!props.id) return;
      if (state === "loading") {
        onLoadStart?.(props.id);
      }
      if (state === "ready") {
        onLoadEnd?.(props.id);
      }
    },
    [onLoadEnd, onLoadStart, props.id]
  );
  useOLEvent(props.source, "change", handleLoadEvent);

  // Handle Selectable features
  useEffect(() => {
    if (!layer) return;
    layer.set("selectable", true);
    registerHandlerForLayer(layer, onSelect);
  }, [onSelect, layer]);

  // Handle modifiable features
  useEffect(() => {
    if (!layer) return;
    if (layer && (onModify || editable)) {
      layer.set("modifiable", true);
    }
  }, [onModify, editable, layer]);

  return null;
}

type VectorSourceLayerProps = Partial<Omit<OlLayerProps, "source" | "onSelect">> &
  ConstructorParameters<typeof VectorSource<Feature>>[0] &
  Omit<ConstructorParameters<typeof VectorImageLayer>[0], "source"> & {
    onSelect?: (
      selected: Feature[],
      deselected: Feature[],
      layer?: string | number,
      name?: string
    ) => void;
    onPostRender?: (e: RenderEvent) => void;
    style?: StyleLike | QM.Style;
  };
function VectorSourceLayer(props: VectorSourceLayerProps) {
  const source = useVectorSource(props);

  return <VectorLayer {...props} source={source} />;
}

export default VectorSourceLayer;
